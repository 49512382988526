<template>
  <Panel></Panel>
</template>

<script>
import Panel from '@/layout/Panel.vue'
export default {
  name: "About",
  components: {
    'Panel': Panel
  },
  data(){},

  mounted(){
    this.proof()
  },

  methods: {
    async proof(){
      // this.$vToastify.success("easy-peasy");
      console.log('hello')
    }
  }
}
</script>