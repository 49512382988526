<template>
    <div>
    </div>
</template>

<script>
export default {
    name: "Panel",
    
}
</script>